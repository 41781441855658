// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "career-journal-20190426200348-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d5yaqrp7ij10z.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:982331f7-4bef-45bd-9be4-a3f7d2473d32",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_pQhBNWZQr",
    "aws_user_pools_web_client_id": "4mv18m9ba2kj6fqongr94fp5th",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://hhiyvksbtfg53p6klshjfs6uay.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
